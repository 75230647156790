import classNames from 'classnames'
import React from 'react'
import {Button, ButtonPriority, TextButton} from 'wix-ui-tpa/cssVars'
import {useMobileFontOverrides} from '../../../../../../commons/hooks/use-mobile-font-overrides'
import {classes} from './event-link.st.css'
import {EventLinkProps} from '.'

export const EventLink: React.FC<EventLinkProps> = ({primary, secondary, mobile, textButton, ...rest}) => {
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  if (textButton) {
    return (
      <TextButton
        {...rest}
        className={classNames(classes.link, {[classes.mobile]: mobile && mobileFontOverridesEnabled})}
      />
    )
  } else {
    let buttonPriority
    let buttonClass

    if (primary) {
      buttonPriority = ButtonPriority.primary
      buttonClass = classes.primaryButton
    } else if (secondary) {
      buttonPriority = ButtonPriority.secondary
      buttonClass = classes.secondaryButton
    }

    return (
      <Button
        {...rest}
        className={classNames(buttonClass, {[classes.mobile]: mobile && mobileFontOverridesEnabled})}
        priority={buttonPriority}
        fullWidth={mobile}
      />
    )
  }
}
